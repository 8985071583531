@import "react-widgets/scss/styles.scss";
@import "~antd/dist/antd.css";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "slick-carousel/slick/slick.css";

:root {
    --blue-primary: #BD42F4;
    --blue-secondary: #2C68F4;
    --blue-light: #f3f6fd;
    --blue-dark: #454B5E;
    --yellow-primary: #E4A118;
    --yellow-secondary: #F16622;
    --yellow-light: #faded0;
    --red-primary: #ec407a;
    --red-secondary: #d81b60;
    --red-light: #ffe6e6;

    --green-primary: #65b7a1;
    --green-secondary: #154437;
    --green-light: #dfffe4;
    --white: white;
    --primary-text: #344767;
    --primary-bg: #F5F5FE;
    --blue-gradient: linear-gradient(195deg,var(--blue-secondary),var(--blue-primary));
    --yellow-gradient: linear-gradient(195deg,var(--yellow-secondary),var(--yellow-primary));
    --red-gradient: linear-gradient(195deg,var(--red-secondary),var(--red-primary));
    --green-gradient: linear-gradient(195deg,var(--green-secondary),var(--green-primary));
    /*--light-blue-gradient: linear-gradient(316deg, #e5d9f2 0%, #cdc1ff99 74%);*/
    --light-blue-gradient:linear-gradient(90deg, #d2e6f8 0%, #e5eaff 74%);
    --light-yellow-gradient: linear-gradient(315deg, #f6ecc4 0%, #f7d4d4 74%);
    --border: 1px solid #d8d8d8;
    --light-black:#525252
}
.box-shadow{
    box-shadow: 0 4px 16px rgba(16, 29, 45, 0.08);
}

.color-blue{
    color: var(--blue-primary);
}
.color-yellow{
    color: var(--yellow-primary);
}
.color-red{
    color: var(--red-primary);
}
.color-green{
    color: var(--green-primary);
}
.color-green-secondary{
    color: var(--green-secondary);
}
.color-yellow-secondary{
    color: var(--yellow-secondary);
}
.color-blue-secondary{
    color: var(--blue-secondary);
}
.color-primary-text{
    color: var(--primary-text);
}
.color-black{
    color: black !important;
}

.stripe-bg-gradient{
    background: var(--light-yellow-gradient)
}


@font-face{
    font-family:'Poppins Light';
    src:url('./fonts/poppins/Poppins-Light.ttf') format('truetype');
    font-weight:500;
    font-style:normal;
}
@font-face{
    font-family:'Poppins Light Italic';
    src:url('./fonts/poppins/Poppins-LightItalic.ttf') format('truetype');
    font-weight:300;
    font-style:italic;
}
@font-face{
    font-family:'Poppins';
    src:url('./fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight:400;
    font-style:normal;
}
@font-face{
    font-family:'Poppins Italic';
    src:url('./fonts/poppins/Poppins-Italic.ttf') format('truetype');
    font-weight:400;
    font-style:italic;
}
@font-face{
    font-family:'Poppins SemiBold';
    src:url('./fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight:500;
    font-style:normal;
}
@font-face{
    font-family:'Poppins SemiBold Italic';
    src:url('./fonts/poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight:600;
    font-style:italic;
}
@font-face{
    font-family:'Poppins Medium';
    src:url('./fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-weight:500;
    font-style:normal;
}
@font-face{
    font-family:'Poppins Bold';
    src:url('./fonts/poppins/Poppins-Bold.ttf') format('truetype');
    font-weight:700;
    font-style:normal;
}
@font-face{
    font-family:'Poppins Bold Italic';
    src:url('./fonts/poppins/Poppins-BoldItalic.ttf') format('truetype');
    font-weight:700;
    font-style:italic;
}
@font-face{
    font-family:'Poppins ExtraBold';
    src:url('./fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
    font-weight:900;
}

body {
    letter-spacing: 0.1px;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
    font-family: Poppins, sans-serif;
    background: transparent;
    overflow-y: visible !important;
    width: 100% !important;
}

#root{
    overflow-x: hidden;
}


.ant-notification{
    z-index: 1112;
}
.ant-modal-wrap{
    z-index: 1111;
}
.ant-drawer-mask{
    z-index: -1;
}
.font-light{
    font-family: "Poppins Light", sans-serif !important;
}
.font-regular{
    font-family: "Poppins", sans-serif;
}

.font-medium{
    font-family: "Poppins Medium", sans-serif !important;
}
.font-semibold{
    font-family: "Poppins SemiBold", sans-serif !important;
}
.font-bold{
    font-family: "Poppins Bold", sans-serif !important;
}
.font-xs {
    font-size: 	0.75em !important;
}
.font-sm {
    font-size: 0.875em !important;
}
.font-legacy {
    font-size: 	1em !important;
}
.font-md {
    font-size: 1.1250em !important;
}
.font-mlg {
    font-size: 1.250em !important;
}
.font-lg {
    font-size: 1.5em !important;
}
.font-xlg {
    font-size: 1.75em !important;
}
.font-xxlg {
    font-size: 2em !important;
}
path > span{
    display: none;
}

.icon{
    flex-shrink:0;
}
.disabled-text{
    color: #8c8c8c;
}

.ant-picker-large{
 padding: 16.5px;
}
.ant-tooltip{
    z-index: 2000;
}

button:disabled{
    border: 1px solid lightgray !important;
    background-color: inherit !important;
    color: #8c8c8c !important;
}

.ant-carousel .slick-dots li button{
    background: #000;
    opacity: 0.7;
}
.ant-carousel .slick-dots li.slick-active button{
    background: var(--yellow-primary);
}
.newMAI_svg__cls-1,.newMAIWithText_svg__cls-3{
    stroke-width: 1.5;
    stroke: var(--blue-primary) !important;
}
.newMAI_svg__cls-2, .newMAI_svg__cls-3, .newMAIWithText_svg__cls-4, .newMAIWithText_svg__cls-5{
    display: none;
}

.ant-tooltip-inner{
    background-color: #000;
}
.ant-empty-image img{
    mix-blend-mode: multiply;
}

.ant-picker-dropdown{
    z-index: 11111;
}
.ant-collapse-header{
    align-items: center !important;
    cursor: pointer !important;
}
.ant-dropdown{
    z-index: 11111;
}

.placeholder-text{
    color: #b2b2b2;
    font-family: "Poppins Light", sans-serif;
}

.ant-radio-button-wrapper:hover{
    color: var(--blue-secondary);
}
.ant-radio-button-wrapper-checked{
    background: var(--blue-primary) !important;
    border-color: var(--blue-primary) !important;
}

.p-CardForm{
    background: red;
}

.gradient-text {
    background: linear-gradient(
            90deg,
            #5ac39a 11.92%,
            #2c68f4 47.2%,
            #bd42f4 74.09%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}
.bottom-fixed-buttons{
    position: fixed;
    bottom: 100px;
    right: 20px;
    z-index: 11;
}














.cta{
  background: linear-gradient(90deg, #bd42f4, #2c68f4);
  color: #fff;
  padding: 60px 0;
}
.cta h2{
  font-size: 35px;
  font-family: 'Poppins ExtraBold';
}
.cta p{
  font-family: 'Poppins Light';
  font-size: 20px;
  word-spacing: 2px;
}
.cta a{
  background: #fff;
  padding: 10px 50px 10px 10px;
  border-radius: 40px;
  display: table;
  text-transform: uppercase;
  font-size: 20px;
  text-decoration: none;
  color: #000;
  float: right;
  margin-top: 50px;
  transition: all 0.5s ease-in-out;
}
.cta a:hover{
  transition: all 0.5s ease-in-out;
  background: #000;
  color: #fff;
}
.cta a span{
  background: #7E52F4;
  padding: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 25px;
}
.cta a img{
  height: 30px;
  width: 30px;
}
.playIcon{
  width: 100px;
  position: absolute;
  left: 46%;
  top: 34%;
  filter: invert(1) drop-shadow(0px 0px 10px #BC42F7);
  transition: all 0.5s ease-in-out;
}
.playIcon:hover{
  filter: invert(1) drop-shadow(0px 0px 10px #4063F4);
  transition: all 0.5s ease-in-out;
}
.contactsec{

}
.contactsec .container{
  position: relative;
  top: -200px;
}
.contactsec .contact-back{
  background: linear-gradient(90deg, #bd42f4, #2c68f4);
  height: 300px;
}
.contactsec img{
  width: 100%;
  height: auto;
  border-radius: 40px;
  filter: drop-shadow(0px 0px 10px #2c68f4);
  padding: 20px;
}
.contact-right{
  background: #fff;
  padding: 25px;
  box-shadow: 0 0 10px #2c68f4;
  border-radius: 20px;
  margin: 20px;
}
.contact-right h2{
  font-size: 40px;
  font-family: 'Poppins ExtraBold';
  background: linear-gradient(90deg, #5ac39a 11.92%, #2c68f4 47.2%, #bd42f4 74.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}
.contact-right p{
  font-size: 18px;
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 100;

}
.contact-right form{}
.contact-right .form-control{
  background: #f1f1f1;
  color: #000;
  margin-bottom: 20px;
  border-radius: 30px;
  border: 1px solid #BD42F4;
  height: 55px;
}
.contact-right textarea.form-control{
  height: 150px;
}
.cntbtn{
  color: #fff;
  background: linear-gradient(90deg, #bd42f4, #2c68f4);
  padding: 10px 30px;
  border: 0;
  border-radius: 20px;
  margin-top: 22px;
  text-decoration: none;
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.home-layout .news-inner .section-header{
    padding-bottom:0;
}
.home-layout .second-section{
    margin-bottom:50px;
}
.site-footer.invert-color {
    padding: 100px 0 25px 0;
}
.invert-color .footer-nav a {
    font-size: 20px;
}
.invert-color .footer-nav ul{margin-bottom:30px;}
.newsMediaHome{padding:100px 20px 40px}
.newsMediaHome .featured-image{border-radius:40px;}
.mobile-footer{display:none;}
.site-footer::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    z-index: -3;
}
.home-hero-section .title-section button{
    transition: all 0.5s ease-in-out;
    transform: scale(1.0);
}
.home-hero-section .title-section button:hover{
    transition: all 0.5s ease-in-out;
    transform: scale(1.1);
}
.home-layout .hero-inner .title{font-size: 60px!important;}
/*===================404 Page=================*/
.layout-404 .hero,.policy-layout .hero,.terms-layout .hero,.biography-layout .hero{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
}
.policy-layout .hero h1,.terms-layout .hero h1,.biography-layout .hero h1{text-align: center;padding: 50px 0;}
.policy-terms{padding: 50px 0;}
.gradbtn{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    border: 0;
    border-radius: 28px;
    color: #fff;
    margin-top: 22px;
    padding: 15px 40px;
    text-decoration: none;
    width: fit-content;
}
.top404{
    max-width: 1000px;
    margin: auto;
    padding:200px 0;
}
.top404 h1{
    font-size:200px;
    display:table;
    margin:0 auto;
}
/*===================About Us=================*/
.singleteam{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    color:#fff;
    padding:20px;
    border-radius:10px;
    margin-top:50px;
}
.singleteam img{
    width:100%;
    border-radius:10px;
}
.singleteam a{
    text-decoration: none;
    color:#fff;
}
.singleteam h2{
    font-size:25px;
    text-align:center;
    margin-top: 20px;;
}
.singleteam p{
    font-size:15px;
    text-align:center;
    width:100%;
    display:block;
}
.singleteam ul{
    list-style-type: none;
    padding: 0;
    text-align: center;
}
.singleteam ul li{
    display: inline-block;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    background: #fff;
    margin-right: 10px;
    border-radius: 50%;
}
.singleteam ul li a{color: #000;}
.singleteam ul li:hover a{color: #fff;}
.singleteam ul li:hover {background: #000;}
.single-team{margin:50px 0;}
.single-team img{
    width:100%;
    border-radius:10px;
}
.single-team h2,.single-team h4{
    font-size: 25px;
    font-weight: 600!important;
}
.single-team p{margin-top: 20px;}
.single-team ul{
    list-style-type: none;
    padding: 0;
}
.single-team ul li{display: inline-block;margin-right: 15px;;}
.single-team ul li a{
    font-size: 24px;
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    width: 50px;
    display: block;
    text-align: center;
    color: #fff;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
}
.about-layout .hero.has-bg-color:before {
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    clip-path: polygon(0 0, 0, 5760px calc(100% - 352px), 0 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.about-top{
    width: 100%;
    border-radius: 30px;
}
.about-top-btn{
  background: #fff;
  padding: 10px 50px 10px 10px;
  border-radius: 40px;
  display: table;
  text-transform: uppercase;
  font-size: 20px;
  text-decoration: none;
  color: #000!important;
  float: right;
  margin-top: 50px;
  transition: all 0.5s ease-in-out;
}
.about-top-btn:hover{
  transition: all 0.5s ease-in-out;
  background: #000;
  color: #fff!important;
}
.about-top-btn span{
  background: #7E52F4;
  padding: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 25px;
}
.about-top-btn img{
  height: 30px;
  width: 30px;
}
.gradient-text-2{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-family: 'Poppins SemiBold';
}
.about-layout .testimonial-container .testimonial.testimonial-active {
    opacity: 1;
    background: #8152f1;
    color: white !important;
}
.gradient-text-2 img{width:100px;}
.about-mv img{width:80%}
  .about-mv .f-content{padding:80px;}
/*===================About Us End=================*/
/*===================Contact Us=================*/
.contact-layout .hero.has-bg-color:before {
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.contact-left h2{
    text-align: left;
    font-family: 'Poppins SemiBold';
}
.contact-left h3{
    text-align: left;
    font-family: 'Poppins SemiBold';
    font-size: 40px;
    margin-bottom: 50px;
}
.contact-left .form-control{
    background: #f1f1f1;
    border-radius: 0;
    margin-bottom: 20px;
    height: 55px;
    border: 0;
    color: #000;
}
.contact-left textarea.form-control{
    height: 150px;
}
.contact-left .cntbtn{
  color: #fff;
  background: linear-gradient(90deg, #bd42f4, #2c68f4);
  padding: 10px 30px;
  border: 0;
  border-radius: 20px;
  margin-top: 22px;
    float: left;
}
.contacts-right{text-align: left;}
.contacts-right  h2{
    text-align: left;
    font-family: 'Poppins SemiBold';
}
.contacts-right h3{
    text-align: left;
    font-family: 'Poppins SemiBold';
    font-size: 40px;
    margin-bottom: 20px;
}
.cicon{margin-bottom: 20px;}
.cicon span{
    background: #5AC39A;
    color: #fff;
    font-size: 45px;
    width: 80px;
    height: 80px;
    text-align: center;
    display: block;
    line-height: 80px;
}
.cicon h4{font-family: 'Poppins SemiBold';}
.cicon p{
color: #777;
    font-size: 20px;
}
/*===================Contact Us End=================*/
/*===================MAI Page=================*/
.mai-layout .hero.has-bg-color:before {
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.mai-top{filter:brightness(0) invert(1);width:100%}
.mai2-content{
    background: #F5F3F3;
    padding: 20px;
    padding-top: 70px;
    margin-bottom: 30px;
}
.mai-features .icon{
    background: linear-gradient(180deg, #bd42f4, #2c68f4);
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    position: relative;
    top:50px;
}
.mai-features .icon img{width: 50px;}
.mai2-content h4{
    font-family: 'Poppins SemiBold';
    font-size: 23px;
}
.mai2-content p{
    font-size: 14px;
    position:relative;
    min-height: 200px;
}
.mai-support .row{align-items: center;}
.mai-support img{
    width: 100%;
    filter: brightness(20%) contrast(200%);
    opacity: 0.5;
}
.cntbtncmn{
    color: #fff;
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    padding: 10px 30px;
    border: 0;
    border-radius: 20px;
    margin-top: 22px;
    float: left;
    text-decoration: none;
    width: fit-content;
}
.mai-features2{background: #F3F5F8;padding:50px 0 80px 0;}
.mai-features2 img{width: 100%;}
.pricingToggle{
    display: table;
    margin: 0 auto;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 40px;
}
.pricingToggle button{
    background: transparent;
    color: #000;
    border: 0;
    font-size: 20px;
    padding: 10px 40px;
    border-radius: 35px;
    margin-right: 20px;
}
.pricingToggle button:last-child{margin-right:0}
.pricingToggle button.active{
    background: #5E59F4;
    color: #fff;
}
.pricing .tiles-item-mai{flex-basis: 33.33%;}
.pricing-mai .h1{}
.pricing-mai .h1 small{color: #777;font-size: 20px;}
.pricing-mai .pricing-item-price{text-align: center;}
.pricing-mai .pricing-item-price span{
    text-transform: uppercase;
    font-size: 22px;
    font-family: 'Poppins SemiBold' !important;
    margin: 20px 0;
    display: block;
}
.tiles-item-mai.dark .tiles-item-inner{background: linear-gradient(-45deg, #bd42f4, #2c68f4);color:#fff;}
.tiles-item-mai.dark .tiles-item-inner .h1 small {color: #ccc;}
.pricing-mai .pricing-item-cta button{
  color: #fff!important;
  background: linear-gradient(90deg, #bd42f4, #2c68f4);
  padding: 10px 30px;
  border: 0;
  border-radius: 30px;
  margin-top: 22px;
}
.pricing-mai .tiles-item-mai.dark .pricing-item-cta button{
  color: #000!important;
  background: #fff;
}
.header-nav li a.active{border-bottom: 2px solid;}
.header-nav li a:hover{border-bottom: 2px solid;}
.extranav{color:#fff;}
.extranav a{color:#fff!important;text-decoration:none!important;margin:0 10px;}
/*===================MAI End=================*/
.featuredInHome img{
    width: 100%;
    /*box-shadow: 0 0 5px #ccc;*/
    border-radius: 5px;
}
/*===================Areas=================*/
.area-layout .hero.has-bg-color:before {
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.area-thumb a{text-decoration: none;}
.area-thumb img{
    width: 100%;
    border-radius: 20px;
    filter: drop-shadow(2px 4px 6px black);
}
.area-thumb span{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    color: #fff;
    padding: 15px 30px;
    border-radius: 30px;
    z-index: 9999;
    display: table;
    margin: 0 auto;
    position: relative;
    top: -27px;
}
.area-service{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    padding: 40px 25px;
    border-radius: 20px;
}
.area-service ul{
    padding-left: 0;
    list-style-type: none;
    margin-bottom:0;
}
.area-service ul li{
    background: #fff;
    margin-bottom: 15px;
    font-size: 22px;
    padding: 10px 20px;
    border-radius: 30px;
}
.area-service ul li:last-child{margin-bottom:0;}
.area-service ul li a{
    text-decoration: none;
    color: #000;
}
.area-service ul li a img{width: 35px;}
.area-service ul li a span{
    float: right;
    background: #B843F4;
    font-size: 17px;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    position: relative;
    top: 1px;
    right: -10px;
}
.areasection .blockquote{
    box-shadow: 0 0 15px #ccc;
    padding: 20px;
    position: relative;
}
.areasection .blockquote:before{
    content:"";
    background: #2c68f4;
    width: 3px;
    display: block;
    height: 125px;
    position: absolute;
    left: 0;
}
.areasection .blockquote p{font-size: 14px;}
/*===================End Areas=================*/
/*===================DNA=================*/
.dna-layout .hero.has-bg-color:before {
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.top-img{width:100%;border-radius:20px;}
.dna-video{position:relative}
.dna-video span{
    position: absolute;
    font-size: 100px;
    color: #fff;
    margin: 0 auto;
    left: 46%;
    top: 46%;
    filter: drop-shadow(0px 0px 7px #BC42F7);
}
.dna-video img{border-radius: 30px;width:100%;}
.dna-features img{border-radius:20px;}
.dna-features .MuiGrid-root{margin-top: 0px !important;}
.dna-features2 .section-inner{padding-top: 0px !important;padding-bottom: 0 !important;}
.features-tiles-item-image{background: linear-gradient(90deg, #bd42f4, #2c68f4);}
.dna-features2.features-tiles .tiles-wrap{margin-left: 0;margin-right: 0;}
.dna-features2.features-tiles  .tiles-item {
    padding: 16px;
    margin: 16px;
    max-width: 33.33%;
    width: 33.33%;
    flex-basis: 28.33%;
    box-shadow: 0 0 10px #B046F4;
    border-radius: 20px;
}
.dna-features2.features-tiles  .tiles-item .font-semibold{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}
.dna-pricing .pricing .tiles-item {
    flex-basis: 33.33%;
    max-width: 33.33%;
}
.dna-pricing .pricing .tiles-item.dark {
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
}
.pricing-mai .price-ribbon-wrapper .ribbon {
    background-color: #3267F5;
}
.dna-testi.testimonial-container .testimonial.testimonial-active {
    opacity: 1;
    background: linear-gradient(-45deg, #bd42f4, #2c68f4);
    color: white !important;
}
.primary-button.gradient{background: linear-gradient(-45deg, #bd42f4, #2c68f4);color: #fff;}
/*===================End DNA=================*/
.home-hero-section .title {
    line-height: 75px;
}
.home-hero-section button .mt-2{font-size: 20px !important;}
.feature-image-overlay .dna{background: linear-gradient(-45deg, #bd42f4, #bd42aa);}

.mt-160{margin-top:160px !important;}
.mb-160{margin-bottom:160px !important;}
.mb-100{margin-bottom: 100px !important;}


.home-layout .second-section{padding-top: 50px;}
.hide-desktop{display:none;}
.news-item-image img{height:auto;}
.blog-inner .topimg{width:100%;margin-bottom:30px;}
.blog-inner h2{
    background: linear-gradient(90deg, #bd42f4, #2c68f4);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-family: 'Poppins SemiBold';
    font-size: 30px;
}
.blog-inner li h3{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins';
    color:#3466F4;
    margin: 20px 0;
}
.blog-inner p{font-size: 15px;}
.blog-inner ul li{font-size: 15px;}
ul.dropdown{
    position: absolute;
    background: #fff;
    padding-left: 0;
    left: -30px;
    display: none;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    border-radius: 5px;
}
ul.dropdown li{list-style-type: none;}
ul.dropdown li a {
    padding-left: 15px !important;
    padding-top: 7.5px !important;
    padding-right: 15px !important;
    padding-bottom: 7.5px !important;
    border-bottom: 1px dashed #3266F4;
}
ul.dropdown li:last-child a{border-bottom: 0 !important;}
ul.dropdown li a:before{
    content:">"
}
nav li{position: relative;}
@media(max-width:767px){
  .hide-mobile{display:none;}
  .hide-desktop{display:block;}
  .home-layout h1.title{margin-bottom: 30px !important;}
  .home-hero h1{line-height: 35px !important;}
  .home-hero-section .home-hero .buttons-row button{display: flex !important;border-radius: 30px;}
  .home-hero-section .home-hero .buttons-row button img:first-child{margin-right: 15px !important;}
  .css-1sos3zc-MuiStack-root>:not(style)~:not(style){margin-left:0!important;}
  .info-section .grid-section:first-child {padding-right: 15px!important;}
  .info-section .grid-section:last-child {padding-left: 15px;}
  .mt-160{margin-top:80px !important;}
  .mb-160{margin-bottom:0px !important;}
  .cta h2 {font-size: 25px;font-family: "Poppins SemiBold" !important;}
  .cta p {font-size: 13px;word-spacing: 0px !important;}
  .cta a{float: none;padding: 3px 16px 3px 3px !important;margin-top: 0 !important;}
  .feature-image-overlay .box:not(:last-child) {margin-right: 8px !important;}
  .feature-image-overlay .box {padding: 6px !important;border-radius: 3px !important;}
  .MuiGrid-item.left-container,.reverse-column .MuiGrid-item{margin-top: 50px;}
  .newsMediaHome{padding:0px 20px 40px}
  .newsMediaHome .featured-image{border-radius:10px;margin-bottom: 15px;}
  .newsMediaHome .section-inner{padding-bottom: 15px;}
  .playIcon {width: 40px;left: 41%;top: 43%;}
  .featured-in-section .subtitle{margin-bottom: 25px;}
  .contactsec .container {top: -300px !important;margin-bottom: -265px;}
  .contact-right h2 {font-size: 20px;font-family: "Poppins SemiBold";}
  .contact-right p {font-size: 12px;}
  .contact-right{margin: 20px 5px;}
  .accordion-header{font-size: 13px;padding-top: 14px;padding-bottom: 14px;text-align: left;}
  .site-footer img{width: 100px;}
  .site-footer.invert-color {padding: 50px 0 100px 0;}
  .invert-color .footer-nav a {font-size: 15px;}
  /*===================Mobile Footer=================*/
  .mobile-footer{display:block;}
  .mobile-footer li img{width: 30px;height:30px;display: block;margin: 0 auto;}
  .mobile-footer li{width: 25%;text-align: center;padding: 6px;border-right: 1px solid #ccc;}
  .mobile-footer{background: #fff;position: fixed;left: 0;right: 0;bottom: 0;box-shadow: 0 0 10px;z-index: 9999999;}
  .mobile-footer li a{text-decoration: none;color: #000;}
  .home-layout .mobile-footer li.home{background: linear-gradient(90deg, #bd42f4, #2c68f4);}
  .home-layout .mobile-footer li.home img{filter: brightness(5);}
  .home-layout .mobile-footer li.home a{color: #fff;}
  .bone-layout .mobile-footer li.bone{background: linear-gradient(90deg, #bd42f4, #2c68f4);}
  .bone-layout .mobile-footer li.bone img{filter: brightness(5);}
  .bone-layout .mobile-footer li.bone a{color: #fff;}
  .dna-layout .mobile-footer li.dna{background: linear-gradient(90deg, #bd42f4, #2c68f4);}
  .dna-layout .mobile-footer li.dna img{filter: brightness(5);}
  .dna-layout .mobile-footer li.dna a{color: #fff;}
  .mai-layout .mobile-footer li.mai{background: linear-gradient(90deg, #bd42f4, #2c68f4);}
  .mai-layout .mobile-footer li.mai img{filter: brightness(5);}
  .mai-layout .mobile-footer li.mai a{color: #fff;}
  /*===================End Mobile Footer=================*/
  .top404{padding:50px 0;}
  .top404 h1{font-size:100px;}
  .gradient-text-2 img{width:80px;}
  .about-mv img{width:100%}
  .about-mv .f-content{padding:0px;}
  .pricingToggle button {font-size: 15px;padding: 5px 20px;margin-right: 20px;}
  .pricing .tiles-item-mai {flex-basis: 95%;padding-left: 0 !important;margin-bottom: 30px;}
  .pricing .tiles-item-mai .has-shadow {box-shadow: 0 0 10px #999;}
  .home-layout .home-hero-section .home-hero .title{font-size: 32px !important;}
  .home-layout .second-section{padding-top: 0px;}
  .home-layout .section-inner{padding-bottom: 0px;}
  .pb-160 {padding-bottom: 50px !important;}
  .pt-160 {padding-top: 50px !important;}
  .home-layout .features-tiles h2{margin:0;}
  .css-1b37mhi-MuiStack-root>:not(style)~:not(style) {margin-top: 0px!important;}
  .mb-100 {margin-bottom: 0px !important;}
  .home-layout .features-tiles.mb-160{margin-bottom:50px!important;padding-bottom:50px!important;}
  .featuredInHome{margin-bottom:50px!important;}
  .featuredInHome .section-inner{padding-top:0px!important;}
  .about-mv{margin-top:50px!important;}
  .testimonial-container{padding: 50px 0px;}
  .testimonial-container .text-column {margin-top: 0;}
  .dna-features2.features-tiles .tiles-item{width:100%;max-width:100%;flex-basis:90%}
  .dna-features2{padding-bottom: 50px;}
  .dna-layout .pricing-mai h2{position: relative;top: -50px;}
  .home-hero-section .home-hero .buttons-row button{min-width: 250px;}
}

