.pricing {

  .section-inner {
    padding-top: $pricing--padding-t__mobile;
    padding-bottom: $pricing--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $pricing-header--padding__mobile;
  }

  .tiles-wrap {
    @if ( $pricing-items--padding__mobile != null ) {
      margin-right: -($pricing-items--padding__mobile / 2);
      margin-left: -($pricing-items--padding__mobile / 2);
      margin-top: -($pricing-items--padding__mobile / 2);

      &:last-of-type {
        margin-bottom: -($pricing-items--padding__mobile / 2);
      }

      &:not(:last-of-type) {
        margin-bottom: ($pricing-items--padding__mobile / 2);
      }
    }

    &.push-left {

      &::after {
        flex-basis: $pricing-item--width;
        max-width: $pricing-item--width;
        @if ( $pricing-items--padding__mobile != null ) {
          padding-left: $pricing-items--padding__mobile / 2;
          padding-right: $pricing-items--padding__mobile / 2;
        }
      }
    }
  }

  .tiles-item {
    flex-basis: $pricing-item--width;
    max-width: $pricing-item--width;
    @if ( $pricing-items--padding__mobile != null ) {
      padding: $pricing-items--padding__mobile / 2;
    }
  }

  .tiles-item-inner {
    padding-top: $pricing-item--inner-padding-v;
    padding-bottom: $pricing-item--inner-padding-v;
    padding-left: $pricing-item--inner-padding-h;
    padding-right: $pricing-item--inner-padding-h;
  }
}

.pricing-item-content {
  flex-grow: 1;
  width: 100%;
}

ul.pricing-item-features-list {

  li {
    display: flex;
    align-items: center;
    margin-bottom: $spacing--shared-v / 2;
  }
}

.pricing-switcher,
.pricing-slider {
  margin-bottom: 48px;
}

.pricing-slider {
  max-width: $pricing-slider--max-w;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  input {
    width: 100%;
  }

  .pricing-slider-value {
    position: absolute;
    @include font-size(xxs);
    @include font-weight(label);
    color: color(low-contrast);
    margin-top: 8px;
    --thumb-size: #{$range-thumb-size};
  }
}

.invert-color {

  .pricing-slider-value {
    color: color(low-contrast-inverse);
  }
}

@include media( '>medium' ) {

  .pricing {

    .section-inner {
      padding-top: $pricing--padding-t__desktop;
      padding-bottom: $pricing--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $pricing-header--padding__desktop;
    }

    @if ( $pricing-items--padding__desktop != null ) {

      .tiles-wrap {
        margin-right: -($pricing-items--padding__desktop / 2);
        margin-left: -($pricing-items--padding__desktop / 2);
        margin-top: -($pricing-items--padding__desktop / 2);

        &:last-of-type {
          margin-bottom: -($pricing-items--padding__desktop / 2);
        }

        &:not(:last-of-type) {
          margin-bottom: ($pricing-items--padding__desktop / 2);
        }

        &.push-left {

          &::after {
            padding-left: $pricing-items--padding__desktop / 2;
            padding-right: $pricing-items--padding__desktop / 2;
          }
        }
      }

      .tiles-item {
        padding: $pricing-items--padding__desktop / 2;
      }
    }
  }

  .pricing-switcher,
  .pricing-slider {
    margin-top: -32px;
    margin-bottom: 64px;
  }
}

.price-ribbon-wrapper {
  width: 140px;
  height: 88px !important;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 2px;
  .ribbon {
    text-align: center;
    transform: rotate(32deg);
    position: relative;
    padding: 7px 0;
    top: 18px;
    right: 12px;
    width: 200px;
    background-color: #ebb134;
    color: #fff;
  }
}

.pricing-slider {

  &.invert-color {

    .form-slider {
      color: get-color(light, 3);
    }
  }

  .form-slider {

    > span {
      font-weight: 500;
    }
  }
}

.pricing-item-header {
  &::after {
    max-width: 88px;
  }
}

.pricing-item-price-currency {
  color: color(base);
}

.pricing-item-price-currency {
  font-weight: 500;
}

.pricing-item-features-title {
  font-weight: 700;
}

ul.pricing-item-features-list {
  @include divider(after);

  li {
    margin-bottom: 0;
    padding: 14px 0;
    @include divider(before);

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M5 11h14v2H5z" fill="' + get-color(dark, 3) + '" fill-rule="nonzero"/></svg>');
      background-repeat: no-repeat;
      order: -1;
    }

    &.is-checked {

      &::after {
        background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="none"><circle fill="' + get-color(alert, success) + '" cx="12" cy="12" r="12"/><path fill="' + get-color(light, 1) + '" d="M10.5 12.267l-2.5-1.6-1 1.066L10.5 16 17 9.067 16 8z"/></g></svg>');
      }
    }
  }
}